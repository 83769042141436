var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Precios Máximos", impedirClose: _vm.impedirClose }
        },
        [
          _c("graficos", {
            attrs: {
              nombre: "Precios Máximos",
              nombreUpper: "PrecioMaximo",
              nombreCamelCase: "precioMaximo"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }