<template>
  <div>
    <div v-loading="cargando">
      <div style="display: flex">
        <div class="filtrosBox">
          <maca-select-box-empleado
            v-model="empleado"
            @change="getDatos"
            :disabled="$usuarioEs.operador()"
          ></maca-select-box-empleado>
          <maca-select-box
            style="margin-top: 10px"
            placeholder="Provincia"
            v-model="provincias"
            :url="urlProvincia"
            @change="provinciaCambiada"
            clearable
            multiple
            :soloIniciales="$usuarioEs.operador() || $usuarioEs.supervisor()"
          ></maca-select-box>
          <div v-if="provincias != null && provincias.length > 0" :key="keyLocalidad">
            <el-select
              style="margin-top: 10px"
              v-model="localidades"
              placeholder="Localidad"
              multiple
              @change="getDatos"
            >
              <el-option
                v-for="item in listaLocalidades"
                :key="item.id"
                :label="item.nombre + ' (' + item.provincia + ')'"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <maca-input-rango-fecha
            style="width: 250px; margin-top: 10px"
            v-model="rangoFechas"
            @change="getDatos"
          ></maca-input-rango-fecha>
        </div>
        <div class="filtrosBox">
          <el-select v-model="esCompradorSecco" @change="getDatos">
            <el-option label="Todos los Compradores" :value="0"></el-option>
            <el-option label="Sólo de Secco" :value="1"></el-option>
            <el-option label="Sólo los que no son de Secco" :value="2"></el-option>
          </el-select>
          <maca-select-box
            v-if="esCompradorSecco == 1"
            style="margin-top: 10px"
            placeholder="Tipo de Comprador"
            icon="el-icon-house"
            v-model="tipoComprador"
            :url="urlTipoComprador"
            seleccionarPrimero
            @change="getDatos"
            multiple
          ></maca-select-box>
          <maca-select-box
            style="margin-top: 10px"
            placeholder="Tipo de Producto"
            icon="el-icon-house"
            v-model="tipoProducto"
            :url="urlTipoProducto"
            @change="getDatos"
            clearable
          ></maca-select-box>
          <maca-select-box
              style="margin-top: 10px"
              placeholder="Tipo de Compra"
              icon="el-icon-house"
              v-model="tipoCompra"
              :url="urlTipoCompra"
              @change="getDatos"
              clearable
          ></maca-select-box>
        </div>
      </div>

      <hr />

      <div style="margin-top: 20px">
        <el-switch
          v-model="ejeMarca"
          active-text="Por Marcas"
          :active-value="1"
          :inactive-value="0"
          @change="limpiarFiltros(), getDatos()"
          :disabled="
              ejeMarca + ejeTipoCalibre + ejeTipoCanal == 2 && !ejeMarca
            "
        ></el-switch>
        <el-switch
          style="margin-left: 30px"
          v-model="ejeTipoCalibre"
          active-text="Por Calibres"
          :active-value="1"
          :inactive-value="0"
          @change="limpiarFiltros(), getDatos()"
          :disabled="
              ejeMarca + ejeTipoCalibre + ejeTipoCanal == 2 && !ejeTipoCalibre
            "
        ></el-switch>
        <el-switch
          style="margin-left: 30px"
          v-model="ejeTipoCanal"
          active-text="Por Canales"
          :active-value="1"
          :inactive-value="0"
          @change="limpiarFiltros(), getDatos()"
          :disabled="
              ejeMarca + ejeTipoCalibre + ejeTipoCanal == 2 && !ejeTipoCanal
            "
        ></el-switch>
      </div>

      <div style="margin-top: 20px">
        <maca-select-box
          v-if="ejeTipoCalibre && ejeTipoCanal"
          placeholder="De todas las marcas"
          icon="el-icon-collection-tag"
          v-model="marca"
          :url="urlMarca"
          @change="getDatos"
          clearable
          :disabled="
              datosColumnaSeleccionada != null || datosFilaSeleccionada != null
            "
        ></maca-select-box>
        <maca-select-box
          v-if="ejeMarca && ejeTipoCanal"
          placeholder="De todos los calibres"
          icon="el-icon-s-operation"
          v-model="tipoCalibre"
          :url="urlTipoCalibre"
          @change="getDatos"
          clearable
          :disabled="
              datosColumnaSeleccionada != null || datosFilaSeleccionada != null
            "
        ></maca-select-box>
        <maca-select-box
          v-if="ejeMarca && ejeTipoCalibre"
          placeholder="De todos los canales"
          icon="el-icon-folder-opened"
          v-model="tipoCanal"
          :url="urlTipoCanal"
          @change="getDatos"
          clearable
          :disabled="
              datosColumnaSeleccionada != null || datosFilaSeleccionada != null
            "
        ></maca-select-box>
      </div>
    </div>
    <div style="margin-top: 40px" v-if="datosBarras != null" v-loading="cargando">
      <div>
        <div v-if="datosColumnaSeleccionada != null">
          <b>Viendo columna "{{ nombreColumnaSeleccionada }}"</b>
          <el-button
            style="margin-left: 5px"
            type="text"
            @click="datosColumnaSeleccionada = null"
          >(Volver)</el-button>
        </div>
        <div v-if="datosFilaSeleccionada != null">
          <b>Viendo fila "{{ nombreFilaSeleccionada }}"</b>
          <el-button
            style="margin-left: 5px"
            type="text"
            @click="datosFilaSeleccionada = null"
          >(Volver)</el-button>
        </div>
      </div>

      <bar-chart
        :key="barChartKey"
        :height="225"
        :datasets="datosBarras.datasets"
        :labels="datosBarras.labels"
        :options="{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ]
            },
            tooltips: {
              callbacks: {
                footer: obtenerFooterTooltipBarras
              }
            },
            plugins: {
              datalabels: {
                color: '#000',
                anchor: 'end',
                align: 'end',
                offset: 5,
                formatter: barFormat
              }
            },
            legend: {
              labels: {
                fillStyle: '#555',
                  fontColor: '#00000000'
              }
            }
          }"
      ></bar-chart>
    </div>
    <div
      style="overflow: auto; margin-top: 20px; max-height: 500px"
      v-if="datosTabla != null && datosBarras == null"
      v-loading="cargando"
    >
      <!-- Comprobar los dos tipos de datos porque en el caso del
    grafico de barras con eje marca y eje calibre, se debe exportar como tabla.
      Y para exportar como tabla, datosTabla no debe estar vacio-->
      <table class="graficoTabla" :key="tablaKey">
        <tr>
          <td></td>
          <th v-for="(columna, j) in datosTabla.columnas" :key="j">
            <el-button type="text" @click="columnaSeleccionada(columna)">
              {{
              columna
              }}
            </el-button>
          </th>
        </tr>
        <tr v-for="(fila, index) in datosTabla.datos" :key="index">
          <td>
            <el-button type="text" @click="filaSeleccionada(fila.nombre)">
              {{
              fila.nombre
              }}
            </el-button>
          </td>
          <td v-for="(columna, j) in datosTabla.columnas" :key="j">
            <el-tooltip :content="fila.nombre + ' \\\\ ' + columna" placement="top">
              <el-button class="celda" type="text">
                {{
                obtenerCeldaTabla(columna, fila)
                }}
              </el-button>
            </el-tooltip>
          </td>
        </tr>
      </table>
    </div>
    <el-button
      v-if="datosBarras != null || datosTabla != null"
      style="margin-top: 20px"
      type="primary"
      @click="generarReporte"
      :disabled="cargando"
    >Exportar a Excel</el-button>
  </div>
</template>

<style lang="css" scoped>
.celda {
  color: black;
  width: 50px;
  height: 50px;
}

.graficoTabla {
  margin-top: 30px;
}

.graficoTabla td {
  padding: 5px;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  border: 1px solid;
}

.graficoTabla td:first-child {
  text-align: left;
  width: auto;
  background: #ddf;
  font-weight: bold;
}

.graficoTabla th {
  padding: 5px;
  width: auto;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  border: 1px solid;
  background: #ddf;
}

.filtrosBox {
  background: #ddd;
  padding: 5px;
  margin-right: 10px;
  width: 300px;
}
</style>

<script>
{
}

import BarChart from "@/components/charts/bar";
import GeneradorExcelTabla from "./reporte_tabla.js";
import GeneradorExcelBarras from "./reporte_barras.js";

export default {
  components: {
    BarChart
  },
  props: ["nombre", "nombreUpper", "nombreCamelCase"],
  data() {
    return {
      datos: null,

      rangoFechas: [],
      esCompradorSecco: 0,
      tipoComprador: [],

      urlMarca: this.$api.URL + "/marca/obtenerTodosSelect",
      urlTipoCalibre: this.$api.URL + "/tipoCalibre/obtenerTodosSelect",
      urlTipoCanal: this.$api.URL + "/tipoCanal/obtenerTodosSelect",
      urlTipoComprador:
        this.$api.URL + "/tipoCompradorSecco/obtenerTodosSelect",
      urlTipoProducto: this.$api.URL + "/tipoProducto/obtenerTodosSelect",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",
      urlTipoCompra: this.$api.URL + "/tipoCompra/obtenerTodosSelect",

      ejeMarca: 0,
      ejeTipoCalibre: 0,
      ejeTipoCanal: 0,

      marca: null,
      tipoCalibre: null,
      tipoCanal: null,
      tipoProducto: null,
      tipoCompra: null,
      provincias: [],
      localidades: null,
      empleado: null,

      nombreColumnaSeleccionada: null,
      nombreFilaSeleccionada: null,
      datosColumnaSeleccionada: null,
      datosFilaSeleccionada: null,

      mostrarPop: null,
      mostrarExhibido: null,

      cargando: false,

      barChartKey: 0,
      tablaKey: 0,
      keyLocalidad: 0,
      cambioTipoGrafico: false,

      listaLocalidades: null
    };
  },
  mounted() {
    let esteMes = this.$moment(new Date()).format("YYYY-MM");

    let hoy = this.$moment(new Date()).format("YYYY-MM-DD");
    let comienzoMes = this.$moment(esteMes + "-01").format("YYYY-MM-DD");

    this.rangoFechas = [comienzoMes, hoy];

    this.$nextTick(() => {
      if (
        this.$usuario().datosEmpleado.detalleProvincia != null &&
        this.$usuario().datosEmpleado.detalleProvincia.length > 0
      ) {
        // seleccionar provincias del usuario
        this.provincias = [];
        this.$usuario().datosEmpleado.detalleProvincia.forEach(prov => {
          let existe = false;

          this.provincias.forEach(provincia => {
            if (provincia.id == prov.provinciaID) {
              existe = true;
            }
          });

          if (!existe) {
            this.provincias.push({
              id: prov.provinciaID,
              nombre: prov.provincia
            });
          }
        });

        // traer localidades de las provincias seleccionadas
        this.provinciaCambiada();

        // seleccionar localidades del usuario
        if (this.localidades === null) {
          // inicializar localidades si esta en nulo
          this.localidades = [];
        }
        this.$usuario().datosEmpleado.detalleProvincia.forEach(prov => {
          let existe = false;

          this.localidades.forEach(localidadID => {
            if (localidadID == prov.localidadID) {
              existe = true;
            }
          });

          if (!existe && prov.localidadID != null) {
            this.localidades.push(prov.localidadID);
          }
        });
      }

      // los supervisores ven de todos los empleados de su provincia
      if (!this.$usuarioEs.supervisor()) {
        this.empleado = {
          id: this.$usuario().datosEmpleado.id,
          nombre: this.$usuario().apellido + " " + this.$usuario().nombre
        };
      }
    });
  },
  methods: {
    async getDatos() {
      // si no hay ejes seleccionados no hacer nada
      if (this.ejeMarca + this.ejeTipoCalibre + this.ejeTipoCanal == 0) {
        this.datos = null;
        return;
      }

      // ignorar si se selecciono "solo de secco" pero todavía
      // no hay tipo comprador seleccionado
      // esperar a que se seleccione automaticamente si ese es el caso
      // o que se seleccione manualmente
      if (this.esCompradorSecco == 1 && this.tipoComprador.length == 0) {
        return;
      }

      this.cargando = true;

      let url = this.$api.URL + "/relevamiento/obtenerDatos" + this.nombreUpper;
      let separador = "?";

      url = url + separador + "ejeMarca=" + this.ejeMarca;
      separador = "&";
      url = url + separador + "ejeTipoCalibre=" + this.ejeTipoCalibre;
      separador = "&";
      url = url + separador + "ejeTipoCanal=" + this.ejeTipoCanal;
      separador = "&";

      if (this.rangoFechas != null) {
        url = url + separador + "fechaDesde=" + this.rangoFechas[0];
        separador = "&";
        url = url + separador + "fechaHasta=" + this.rangoFechas[1];
        separador = "&";
      }

      // Agregar filtros del tercer eje
      // Al limpiar los select-box, esto ejecuta antes de que se borren completamente
      // los campos, dando lugar a que haya un objeto
      /*
      tipoCalibre: {
        id: undefined,
        nombre: undefined
      }
      */
      // verificar tambien esto con this.tipoCalibre.id != null
      if (this.marca != null && this.marca.id != null) {
        url = url + separador + "marcaID=" + this.marca.id;
        separador = "&";
      }
      if (this.tipoCalibre != null && this.tipoCalibre.id != null) {
        url = url + separador + "tipoCalibreID=" + this.tipoCalibre.id;
        separador = "&";
      }
      if (this.tipoCanal != null && this.tipoCanal.id != null) {
        url = url + separador + "tipoCanalID=" + this.tipoCanal.id;
        separador = "&";
      }
      if (this.tipoProducto != null && this.tipoProducto.id != null) {
        url = url + separador + "tipoProductoID=" + this.tipoProducto.id;
        separador = "&";
      }
      if (this.tipoCompra != null && this.tipoCompra.id != null) {
        url = url + separador + "tipoCompraID=" + this.tipoCompra.id;
        separador = "&";
      }
      if (this.empleado != null) {
        url = url + separador + "empleadoID=" + this.empleado.id;
        separador = "&";
      }

      if (this.provincias != null && this.provincias.length > 0) {
        let provinciasSel = [];
        this.provincias.forEach(prov => {
          provinciasSel.push({ id: prov.id });
        });

        url =
          url + separador + "detalleProvincia=" + JSON.stringify(provinciasSel);
        separador = "&";
      }
      if (this.localidades != null && this.localidades.length > 0) {
        let localidadesSel = [];
        this.localidades.forEach(local => {
          if (local != null) {
            localidadesSel.push({ id: local });
          }
        });

        if (localidadesSel.length > 0) {
          url =
            url +
            separador +
            "detalleLocalidad=" +
            JSON.stringify(localidadesSel);
          separador = "&";
        }
      }

      if (this.esCompradorSecco == 1) {
        let listaIDs = [];

        this.tipoComprador.forEach(element => {
          listaIDs.push({ id: element.id });
        });
        url =
          url + separador + "tipoCompradorSecco=" + JSON.stringify(listaIDs);
        separador = "&";

        url = url + separador + "esCompradorSecco=1";
        separador = "&";
      } else if (this.esCompradorSecco == 2) {
        url = url + separador + "esCompradorSecco=0";
        separador = "&";
      }

      let respuestaApi = await this.$api.get(url, this.$usuarioToken(), 0);

      this.cargando = false;

      if (respuestaApi != null) {
        this.datos = respuestaApi;
      }
    },
    obtenerCeldaTabla(columna = "", fila = []) {
      let valorExistente = null;

      fila.detalle.forEach(valor => {
        if (valor.nombre == columna) {
          valorExistente = valor[this.nombreCamelCase];
        }
      });

      if (valorExistente != null) {
        if (valorExistente == 0) {
          return "";
        }
        return valorExistente;
      } else {
        return "";
      }
    },
    columnaSeleccionada(nombreSeleccionada) {
      let datosColumna = [];

      this.datosTabla.datos.forEach(fila => {
        fila.detalle.forEach(col => {
          if (col.nombre == nombreSeleccionada) {
            let nuevoItem = {};

            nuevoItem["nombre"] = fila.nombre;
            nuevoItem[this.nombreCamelCase] = col[this.nombreCamelCase];

            datosColumna.push(nuevoItem);
          }
        });
      });

      this.nombreColumnaSeleccionada = nombreSeleccionada;
      this.datosColumnaSeleccionada = datosColumna;
    },
    filaSeleccionada(nombreSeleccionada) {
      let datosFila = [];

      this.datosTabla.datos.forEach(fila => {
        if (fila.nombre == nombreSeleccionada) {
          fila.detalle.forEach(col => {
            let nuevoItem = {};

            nuevoItem["nombre"] = col.nombre;
            nuevoItem[this.nombreCamelCase] = col[this.nombreCamelCase];

            datosFila.push(nuevoItem);
          });
        }
      });

      this.nombreFilaSeleccionada = nombreSeleccionada;
      this.datosFilaSeleccionada = datosFila;
    },
    limpiarFiltros() {
      this.marca = null;
      this.tipoCalibre = null;
      this.tipoCanal = null;

      this.datosColumnaSeleccionada = null;
      this.datosFilaSeleccionada = null;

      this.nombreFilaSeleccionada = null;
      this.nombreColumnaSeleccionada = null;
    },
    generarReporte() {
      if (this.datosTabla != null) {
        let nombreEje1 = "";
        let nombreEje2 = "";

        if (this.ejeMarca && this.ejeTipoCalibre) {
          nombreEje1 = "Marca";
          nombreEje2 = "Tipo Calibre";
        }
        if (this.ejeMarca && this.ejeTipoCanal) {
          nombreEje1 = "Tipo Canal";
          nombreEje2 = "Marca";
        }
        if (this.ejeTipoCalibre && this.ejeTipoCanal) {
          nombreEje1 = "Tipo Canal";
          nombreEje2 = "Tipo Calibre";
        }

        GeneradorExcelTabla.run(
          //form
          {
            nombreReporte: this.nombre,
            rango: this.rangoFechas,
            marca: this.marca,
            tipoCalibre: this.tipoCalibre,
            tipoCanal: this.tipoCanal,
            tipoProducto: this.tipoProducto,
            tipoCompra: this.tipoCompra,
            nombreEje1: nombreEje1,
            nombreEje2: nombreEje2,
            nombreDatoCelda: this.nombreCamelCase,
            esCompradorSecco: this.esCompradorSecco,
            tipoComprador: this.tipoComprador
          },
          // data
          this.datosTabla
        );
      } else {
        let nombreEje = "";
        let filtro = "";

        // verificar si se selecciono una fila o columna de una tabla o
        // si solo se selecciono un eje
        if (this.ejeMarca + this.ejeTipoCalibre + this.ejeTipoCanal == 2) {
          if (this.ejeMarca && this.ejeTipoCalibre) {
            if (this.datosFilaSeleccionada != null) {
              // fila
              nombreEje = "Tipo Calibre";
              filtro = "Marca " + this.nombreFilaSeleccionada;
            } else {
              // columna
              nombreEje = "Marca";
              filtro = "Tipo Calibre " + this.nombreColumnaSeleccionada;
            }
          }
          if (this.ejeMarca && this.ejeTipoCanal) {
            if (this.datosFilaSeleccionada != null) {
              // fila
              nombreEje = "Marca";
              filtro = "Tipo Canal " + this.nombreFilaSeleccionada;
            } else {
              // columna
              nombreEje = "Tipo Canal";
              filtro = "Marca " + this.nombreColumnaSeleccionada;
            }
          }
          if (this.ejeTipoCalibre && this.ejeTipoCanal) {
            if (this.datosFilaSeleccionada != null) {
              // fila
              nombreEje = "Tipo Calibre";
              filtro = "Tipo Canal " + this.nombreFilaSeleccionada;
            } else {
              // columna
              nombreEje = "Tipo Canal";
              filtro = "Tipo Calibre " + this.nombreColumnaSeleccionada;
            }
          }
        } else {
          if (this.ejeMarca) {
            nombreEje = "Marca";
          }
          if (this.ejeTipoCalibre) {
            nombreEje = "Tipo Calibre";
          }
          if (this.ejeTipoCanal) {
            nombreEje = "Tipo Canal";
          }
        }

        GeneradorExcelBarras.run(
          //form
          {
            nombreReporte: this.nombre,
            rango: this.rangoFechas,
            marca: this.marca,
            tipoCalibre: this.tipoCalibre,
            tipoCanal: this.tipoCanal,
            tipoProducto: this.tipoProducto,
            tipoCompra: this.tipoCompra,
            nombreEje: nombreEje,
            filtro: filtro,
            nombreDato: this.nombre,
            esCompradorSecco: this.esCompradorSecco,
            tipoComprador: this.tipoComprador
          },
          // data
          this.datosBarras
        );
      }
    },
    barFormat(value, ctx) {
      let result = parseFloat(value).toFixed(1);
      return result;
    },
    obtenerFooterTooltipBarras(tooltipItems, data) {
      //esto solo es para esta combinacion de ejes
      if (this.ejeMarca + this.ejeTipoCalibre != 2) {
        return null;
      }

      var result = "";

      let objetoDatos = this.datos.formatoGrafico[
        this.nombreCamelCase + "TipoCalibrePorMarcaGrafico"
      ];

      let marcas = null;

      objetoDatos.forEach(calibre => {
        if (calibre.nombre == tooltipItems[0].label) {
          marcas = calibre.detalle;
        }
      });

      marcas.forEach(marca => {
        if (marca[this.nombreCamelCase] != 0) {
          result += marca.nombre + ": " + marca[this.nombreCamelCase] + "\n";
        }
      });

      return result;
    },
    provinciaCambiada() {
      this.listaLocalidades = [];
      this.localidades = null;
      this.provincias.forEach(async prov => {
        let respuestaApi = await this.$api.get(
          this.$api.URL + "/localidad/obtenerTodos?provinciaID=" + prov.id,
          this.$usuarioToken()
        );

        respuestaApi.forEach(localidad => {
          let existe = false;

          this.listaLocalidades.forEach(localEnArray => {
            if (localEnArray.id == localidad.id) {
              existe = true;
            }
          });

          if (!existe) {
            this.listaLocalidades.push(localidad);
          }
        });
      });

      this.getDatos();
    }
  },
  computed: {
    datosBarras() {
      // si se cambio algun parametro/filtro, borrar datos
      if (this.cargando) {
        return null;
      }

      // si hay 1 solo eje, o hay una fila/columna seleccionada, es grafico de barra
      // si es eje marca y eje calibre es barras
      if (
        this.ejeMarca + this.ejeTipoCalibre + this.ejeTipoCanal != 1 &&
        this.ejeMarca + this.ejeTipoCalibre != 2
      ) {
        if (
          this.datosFilaSeleccionada == null &&
          this.datosColumnaSeleccionada == null
        ) {
          // el grafico no es de barra
          return null;
        }
      }

      if (this.datos == null) {
        return null;
      }

      let datasets = [];
      let labels = [];

      datasets.push({
        label: this.nombre,
        backgroundColor: "#dd3333",
        data: []
      });

      let objetoDatos = null;
      if (this.ejeMarca) {
        objetoDatos = this.datos.formatoGrafico[this.nombreCamelCase + "Marca"];
      }
      if (this.ejeTipoCalibre) {
        objetoDatos = this.datos.formatoGrafico[
          this.nombreCamelCase + "TipoCalibre"
        ];
      }
      if (this.ejeTipoCanal) {
        objetoDatos = this.datos.formatoGrafico[
          this.nombreCamelCase + "TipoCanal"
        ];
      }
      if (this.ejeMarca + this.ejeTipoCalibre == 2) {
        objetoDatos = this.datos.formatoGrafico[
          this.nombreCamelCase + "TipoCalibrePorMarcaGrafico"
        ];
      }

      if (this.datosColumnaSeleccionada != null) {
        objetoDatos = this.datosColumnaSeleccionada;
      }
      if (this.datosFilaSeleccionada != null) {
        objetoDatos = this.datosFilaSeleccionada;
      }

      // TODO: en algun momento pasa por aqui y causa un error
      // cuando se cambia rapido los switchs por ej
      if (objetoDatos == null) {
        return null;
      }

      objetoDatos.forEach(e => {
        if (this.ejeMarca + this.ejeTipoCalibre == 2) {
          // grafico con 2 ejes
          // sacar barras que tengan valor 0
          if (e.promedio != 0) {
            labels.push(e.nombre);
            datasets[0].data.push(e.promedio);
          }
        } else {
          // grafico normal
          // sacar barras que tengan valor 0
          if (e[this.nombreCamelCase] != 0) {
            labels.push(e.nombre);
            datasets[0].data.push(e[this.nombreCamelCase]);
          }
        }
      });

      return {
        datasets: datasets,
        labels: labels
      };
    },
    datosTabla() {
      // si se cambio algun parametro/filtro, borrar datos
      if (this.cargando) {
        return null;
      }

      // si hay 2 ejes es tabla
      if (this.ejeMarca + this.ejeTipoCalibre + this.ejeTipoCanal != 2) {
        return null;
      }

      if (this.datosColumnaSeleccionada || this.datosFilaSeleccionada) {
        return null;
      }

      if (this.datos == null) {
        return null;
      }

      let objetoDatos = null;
      if (this.ejeMarca && this.ejeTipoCalibre) {
        objetoDatos = this.datos.formatoTabla[
          this.nombreCamelCase + "TipoCalibrePorMarca"
        ];
      }
      if (this.ejeTipoCanal && this.ejeTipoCalibre) {
        objetoDatos = this.datos.formatoTabla[
          this.nombreCamelCase + "TipoCalibrePorTipoCanal"
        ];
      }
      if (this.ejeTipoCanal && this.ejeMarca) {
        objetoDatos = this.datos.formatoTabla[
          this.nombreCamelCase + "MarcaPorTipoCanal"
        ];
      }

      // TODO: en algun momento pasa por aqui y causa un error
      if (objetoDatos == null) {
        return null;
      }

      //obtener lista de columnas
      let columnas = [];
      objetoDatos.forEach(fila => {
        fila.detalle.forEach(col => {
          if (
            columnas.findIndex(el => {
              return el == col.nombre;
            }) == -1
          ) {
            columnas.push(col.nombre);
          }
        });
      });

      return {
        datos: objetoDatos,
        columnas: columnas
      };
    }
  },
  watch: {
    datosBarras() {
      // forzar refrescado de grafico al obtener nuevos datos. Esto es
      // para los filtros
      this.barChartKey += 1;
    },
    datosTabla() {
      // forzar refrescado de grafico al obtener nuevos datos. Esto es
      // para los filtros
      this.tablaKey += 1;
    },
    provincias() {
      this.keyLocalidad += 1;
    }
  }
};
</script>
