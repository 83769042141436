<template>
  <div>
    <div :style="{ height: mapData.height + 'px', width: '100%' }">
      <l-map
        ref="map"
        :zoom="mapData.zoom"
        :center="mapData.center"
        v-loading="cargando"
        @click="(data) => $emit('mapClicked', data)"
      >
        <l-tile-layer :url="mapData.url"></l-tile-layer>
        <l-marker-cluster>
          <l-marker
            v-for="(item, index) in markers"
            :key="index"
            :lat-lng="item.latlng"
            :icon="getClaseMarcador(item)"
            @click="$refs.modalVer.abrir(item.id)"
          ></l-marker>
        </l-marker-cluster>
        <l-control position="topleft">
          <div class="mapControlContainerFiltros">
            <div class="titulo">
              <h6>Filtros</h6>
              <el-button
                type="text"
                @click="
                  filtrosControlVisible
                    ? (filtrosControlVisible = false)
                    : (filtrosControlVisible = true)
                "
                style="width: 50px"
                icon="el-icon-arrow-down"
              ></el-button>
            </div>
            <div style="margin-top: 10px" v-if="filtrosControlVisible">
              <b>Empleado</b>
              <maca-select-box-empleado
                v-model="empleado"
                :disabled="$usuarioEs.operador()"
              ></maca-select-box-empleado>
              <br />
              <b>Provincia</b>
              <maca-select-box
                placeholder="Provincia"
                v-model="provincias"
                :url="urlProvincia"
                clearable
                multiple
                @change="
                  () => {
                    provinciaCambiada();
                    getDatos();
                  }
                "
                :soloIniciales="
                  $usuarioEs.operador() || $usuarioEs.supervisor()
                "
              ></maca-select-box>
              <br />
              <div
                v-if="provincias != null && provincias.length > 0"
                :key="keyLocalidad"
              >
                <b>Localidad</b>
                <el-select
                  style="margin-top: 10px"
                  v-model="localidades"
                  placeholder="Localidad"
                  multiple
                  @change="getDatos"
                >
                  <el-option
                    v-for="item in listaLocalidades"
                    :key="item.id"
                    :label="item.nombre + ' (' + item.provincia + ')'"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <br />
              <b>Rango de Fechas</b>
              <maca-input-rango-fecha
                style="width: 250px"
                v-model="rangoFechas"
              ></maca-input-rango-fecha>
              <br />
              <b>Marcas</b>
              <maca-select-box
                placeholder="Marcas"
                v-model="marcas"
                :url="urlMarca"
                clearable
                multiple
                @change="
                  () => {
                    getDatos();
                  }
                "
              ></maca-select-box>
              <br />
              <b>Tipo Producto</b>
              <maca-select-box
                placeholder="Tipo Producto"
                v-model="tipoProducto"
                :url="urlTipoProducto"
                clearable
                multiple
                @change="
                  () => {
                    getDatos();
                  }
                "
              ></maca-select-box>
              <br />
              <b>Tipo Extra</b>
              <maca-select-box
                placeholder="Tipo Extra"
                v-model="tipoExtra"
                :url="urlTipoExtra"
                clearable
                multiple
                @change="
                  () => {
                    getDatos();
                  }
                "
              ></maca-select-box>
              <br />
              <b>Tipo Compra</b>
              <maca-select-box
                  placeholder="Tipo Compra"
                  v-model="tipoCompra"
                  :url="urlTipoCompra"
                  clearable
                  multiple
                  @change="
                  () => {
                    getDatos();
                  }
                "
              ></maca-select-box>
              <br />
              <el-button
                icon="el-icon-download"
                type="text"
                v-loading="cargandoExcel"
                :disabled="cargandoExcel"
                @click="descargarExcelIntegrador"
                >Excel Integrador</el-button
              >
              <br />
              <el-button
                icon="el-icon-download"
                type="text"
                v-loading="cargandoExcel"
                :disabled="cargandoExcel"
                @click="descargarCoberturaPopEx"
                >Cobertura Pop/Exhibido</el-button
              >
            </div>
          </div>
        </l-control>
        <l-control position="topright">
          <slot name="topRight"></slot>
        </l-control>
      </l-map>
    </div>
    <modal-ver
      ref="modalVer"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-ver>
  </div>
</template>

<style lang="css">
.mapControlContainerFiltros {
  background: #ffffff88;
  height: 600px;
  width: auto;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
}
.mapControlContainerFiltros > .titulo {
  display: flex;
  height: 40px;
}
.mapControlContainerFiltros > .titulo > h6 {
  margin: auto;
  margin-left: 0px;
}
.mapControlContainerDatos {
  background: #ffffff88;
  height: auto;
  width: auto;
  border-radius: 10px;
  padding: 10px;
}
.mapControlContainerDatos > .titulo {
  display: flex;
  height: 40px;
}
.mapControlContainerDatos > .titulo > h6 {
  margin: auto;
  margin-left: 0px;
}

.leaflet-marker-icon.marker-cluster {
  display: flex;
}

.leaflet-marker-icon.marker-cluster > div {
  margin: auto;
  min-width: 30px;
  display: flex;
  background: #4bf1ff78;
  padding: 5px;
  border-radius: 100%;
  border: 1px solid #004fffcc;
  box-shadow: #0005 1px 2px 2px 1px;
}

.leaflet-marker-icon.marker-cluster > div > * {
  margin: auto;
  font-weight: bold;
}
</style>

<script>
import ModalVer from "@/components/relevamientos/modales/ver";
import ReporteCoberturaPopEx from "@/components/relevamientos/reporte_cobertura_popex.js";
import ReporteExcelIntegrador from "@/components/relevamientos/reporte_excel_integrador.js";

export default {
  name: "home",
  components: { ModalVer },
  data() {
    let esteMes = this.$moment(new Date()).format("YYYY-MM");

    let hoy = this.$moment(new Date()).format("YYYY-MM-DD");
    let comienzoMes = this.$moment(esteMes + "-01").format("YYYY-MM-DD");

    return {
      mapData: {
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        zoom: 4,
        center: [-37.2411793, -62.6314284],
        height: window.innerHeight - 50, // restar la barra de navegacion,
      },

      iconoNoSecco: L.icon({
        iconUrl: "img/icons/nosecco.png",
        iconSize: [30, 42],
        iconAnchor: [15, 40],
        popupAnchor: [0, -40],
        shadowUrl: "img/icons/shadow.png",
        shadowSize: [47, 42],
        shadowAnchor: [20, 40],
      }),
      iconoSecco: L.icon({
        iconUrl: "img/icons/secco.png",
        iconSize: [30, 42],
        iconAnchor: [15, 40],
        popupAnchor: [0, -40],
        shadowUrl: "img/icons/shadow.png",
        shadowSize: [47, 42],
        shadowAnchor: [20, 40],
      }),

      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",
      urlLocalidad: this.$api.URL + "/localidad/obtenerTodos?provinciaID=1",
      urlMarca: this.$api.URL + "/marca/obtenerTodosSelect",
      urlTipoExtra: this.$api.URL + "/tipoExtraPuntoVenta/obtenerTodosSelect",
      urlTipoProducto: this.$api.URL + "/tipoProducto/obtenerTodosSelect",
      urlTipoCompra: this.$api.URL + "/tipoCompra/obtenerTodosSelect",

      empleado: null,
      provincias: [],
      marcas: [],
      tipoExtra: [],
      tipoProducto: [],
      tipoCompra: [],
      localidades: null,
      markers: [],
      rangoFechas: [comienzoMes, hoy],
      datosRelevamientos: null,
      filtrosControlVisible: true,
      rangoControlVisible: true,
      datosControlVisible: true,
      cargando: false,

      keyLocalidad: 0,
      cargandoExcel: false,
      listaLocalidades: null,
    };
  },
  mounted() {
    this.$emit("rangoFechasCambiado", this.rangoFechas);
    this.$emit("empleadoCambiado", this.empleado);

    this.mapData.height = window.innerHeight - 50;

    this.$nextTick(() => {
      this.$refs.map.mapObject.zoomControl.remove();
      // seleccionar provincias del usuario
      this.provincias = [];
      this.$usuario().datosEmpleado.detalleProvincia.forEach((prov) => {
        let existe = false;

        this.provincias.forEach((provincia) => {
          if (provincia.id == prov.provinciaID) {
            existe = true;
          }
        });

        if (!existe) {
          this.provincias.push({
            id: prov.provinciaID,
            nombre: prov.provincia,
          });
        }
      });

      // traer localidades de las provincias seleccionadas
      if (this.provincias.length == 1){
        this.provinciaCambiada();
      }

      // seleccionar localidades del usuario
      if (this.localidades === null) {
        // inicializar localidades si esta en nulo
        this.localidades = [];
      }
      this.$usuario().datosEmpleado.detalleProvincia.forEach((prov) => {
        let existe = false;

        this.localidades.forEach((localidadID) => {
          if (localidadID == prov.localidadID) {
            existe = true;
          }
        });

        if (!existe && prov.localidadID != null) {
          this.localidades.push(prov.localidadID);
        }
      });

      // llenar select empleado
      // los supervisores ven de todos los empleados de su provincia
      if (!this.$usuarioEs.supervisor()) {
        this.empleado = {
          id: this.$usuario().datosEmpleado.id,
          nombre: this.$usuario().apellido + " " + this.$usuario().nombre,
        };
      }

      this.getDatos();
    });
  },
  methods: {
    getClaseMarcador(item) {
      if (item.compradorSecco) {
        return L.icon({
          iconUrl: "img/icons/secco.png",
          iconSize: [30, 42],
          iconAnchor: [15, 40],
          popupAnchor: [0, -40],
          shadowUrl: "img/icons/shadow.png",
          shadowSize: [47, 42],
          shadowAnchor: [20, 40],
        });
      } else {
        return L.icon({
          iconUrl: "img/icons/nosecco.png",
          iconSize: [30, 42],
          iconAnchor: [15, 40],
          popupAnchor: [0, -40],
          shadowUrl: "img/icons/shadow.png",
          shadowSize: [47, 42],
          shadowAnchor: [20, 40],
        });
      }
    },
    async getDatos() {
      this.cargando = true;

      let url = this.$api.URL + "/relevamiento/obtenerTodosSimple";
      let separador = "?";

      if (this.rangoFechas != null) {
        url = url + separador + "fechaDesde=" + this.rangoFechas[0];
        separador = "&";
        url = url + separador + "fechaHasta=" + this.rangoFechas[1];
        separador = "&";
      }

      if (this.empleado != null) {
        url = url + separador + "empleadoID=" + this.empleado.id;
        separador = "&";
      }

      if (this.provincias != null && this.provincias.length > 0) {
        let provinciasSel = [];
        this.provincias.forEach((prov) => {
          provinciasSel.push({ id: prov.id });
        });

        url =
          url + separador + "detalleProvincia=" + JSON.stringify(provinciasSel);
        separador = "&";
      }

      if (this.localidades != null && this.localidades.length > 0) {
        let localidadesSel = [];
        this.localidades.forEach((local) => {
          if (local != null) {
            localidadesSel.push({ id: local });
          }
        });

        if (localidadesSel.length > 0) {
          url =
            url +
            separador +
            "detalleLocalidad=" +
            JSON.stringify(localidadesSel);
          separador = "&";
        }
      }

      if (this.marcas != null && this.marcas.length > 0) {
        let marcasSel = [];
        this.marcas.forEach((marca) => {
          marcasSel.push({ id: marca.id });
        });

        url = url + separador + "marca=" + JSON.stringify(marcasSel);
        separador = "&";
      }

      if (this.tipoExtra != null && this.tipoExtra.length > 0) {
        let tipoExtraSel = [];
        this.tipoExtra.forEach((marca) => {
          tipoExtraSel.push({ id: marca.id });
        });

        url =
          url +
          separador +
          "tipoExtraPuntoVenta=" +
          JSON.stringify(tipoExtraSel);
        separador = "&";
      }

      if (this.tipoProducto != null && this.tipoProducto.length > 0) {
        let tipoProductoSel = [];
        this.tipoProducto.forEach((marca) => {
          tipoProductoSel.push({ id: marca.id });
        });

        url =
          url + separador + "tipoProducto=" + JSON.stringify(tipoProductoSel);
        separador = "&";
      }

      if (this.tipoCompra != null && this.tipoCompra.length > 0) {
        let tipoCompraSel = [];
        this.tipoCompra.forEach((marca) => {
          tipoCompraSel.push({ id: marca.id });
        });

        url =
            url + separador + "tipoCompra=" + JSON.stringify(tipoCompraSel);
        separador = "&";
      }

      let respuestaApi = await this.$api.get(url, this.$usuarioToken());

      this.cargando = false;

      if (respuestaApi != null) {
        this.datosRelevamientos = respuestaApi.datos;
        this.markers = [];

        respuestaApi.datos.forEach((item) => {
          this.markers.push({
            latlng: {
              lat: item.latitud,
              lng: item.longitud,
            },
            id: item.id,
            puntoVenta: item.puntoVenta,
            fecha: item.fecha,
            hora: item.hora,
            compradorSecco: item.compradorSecco,
            marcas: ["secco", "otra"],
          });
        });
      }
    },
    async descargarCoberturaPopEx() {
      let url =
        this.$api.URL + "/relevamiento/obtenerDatosCobertura?ejeMarca=1";
      let separador = "&";

      if (this.rangoFechas != null) {
        url = url + separador + "fechaDesde=" + this.rangoFechas[0];
        separador = "&";
        url = url + separador + "fechaHasta=" + this.rangoFechas[1];
        separador = "&";
      }

      if (this.empleado != null) {
        url = url + separador + "empleadoID=" + this.empleado.id;
        separador = "&";
      }

      if (this.provincias != null && this.provincias.length > 0) {
        let provinciasSel = [];
        this.provincias.forEach((prov) => {
          provinciasSel.push({ id: prov.id });
        });

        url =
          url + separador + "detalleProvincia=" + JSON.stringify(provinciasSel);
        separador = "&";
      }
      if (this.localidades != null && this.localidades.length > 0) {
        let localidadesSel = [];
        this.localidades.forEach((local) => {
          localidadesSel.push({ id: local });
        });

        url =
          url +
          separador +
          "detalleLocalidad=" +
          JSON.stringify(localidadesSel);
        separador = "&";
      }

      this.cargandoExcel = true;

      let respuestaApi = await this.$api.get(url, this.$usuarioToken());

      this.cargandoExcel = false;

      let nombreLocalidades = [];

      if (this.localidades != null) {
        this.localidades.forEach((localSel) => {
          this.listaLocalidades.forEach((local) => {
            if (localSel == local.id) {
              nombreLocalidades.push({
                id: local.id,
                nombre: local.nombre,
              });
            }
          });
        });
      }

      ReporteCoberturaPopEx.run(
        //form
        {
          rangoFechas: this.rangoFechas,
          empleado: this.empleado,
          provincias: this.provincias,
          localidades: nombreLocalidades,
          cantidadPV: respuestaApi.resumen.totalRelevamientos,
          coberturaPop: respuestaApi.resumen.coberturaPop,
          coberturaExhibido: respuestaApi.resumen.coberturaExhibido,
        },
        // data
        respuestaApi
      );
    },
    async descargarExcelIntegrador() {
      let url = this.$api.URL + "/relevamiento/obtenerEstadisticasReporte";
      let separador = "?";

      if (this.rangoFechas != null) {
        url = url + separador + "fechaDesde=" + this.rangoFechas[0];
        separador = "&";
        url = url + separador + "fechaHasta=" + this.rangoFechas[1];
        separador = "&";
      }

      if (this.empleado != null) {
        url = url + separador + "empleadoID=" + this.empleado.id;
        separador = "&";
      }

      if (this.provincias != null && this.provincias.length > 0) {
        let provinciasSel = [];
        this.provincias.forEach((prov) => {
          provinciasSel.push({ id: prov.id });
        });

        url =
          url + separador + "detalleProvincia=" + JSON.stringify(provinciasSel);
        separador = "&";
      }
      if (this.localidades != null && this.localidades.length > 0) {
        let localidadesSel = [];
        this.localidades.forEach((local) => {
          localidadesSel.push({ id: local });
        });

        url =
          url +
          separador +
          "detalleLocalidad=" +
          JSON.stringify(localidadesSel);
        separador = "&";
      }

      this.cargandoExcel = true;

      this.$message({
        message: "Procesando datos. Por favor espere hasta obtener el Excel.",
        type: "info",
        icon: "el-icon-info",
        showClose: true,
        duration: 0,
      });

      let respuestaApi = await this.$api.get(url, this.$usuarioToken(), 0);

      this.cargandoExcel = false;

      let nombreLocalidades = [];

      if (this.localidades != null) {
        this.localidades.forEach((localSel) => {
          this.listaLocalidades.forEach((local) => {
            if (localSel == local.id) {
              nombreLocalidades.push({
                id: local.id,
                nombre: local.nombre,
              });
            }
          });
        });
      }

      ReporteExcelIntegrador.run(
        //form
        {
          rangoFechas: this.rangoFechas,
          empleado: this.empleado,
          provincias: this.provincias,
          localidades: nombreLocalidades,
        },
        // data
        respuestaApi
      );
    },
    provinciaCambiada() {
      if (this.provincias.length == 1){
        this.listaLocalidades = [];
        this.localidades = null;
        this.provincias.forEach(async (prov) => {
          let respuestaApi = await this.$api.get(
              this.$api.URL + "/localidad/obtenerTodos?provinciaID=" + prov.id,
              this.$usuarioToken()
          );

          respuestaApi.forEach((localidad) => {
            let existe = false;

            this.listaLocalidades.forEach((localEnArray) => {
              if (localEnArray.id == localidad.id) {
                existe = true;
              }
            });

            if (!existe) {
              this.listaLocalidades.push(localidad);
            }
          });
        });
      }
    },
  },
  watch: {
    rangoFechas() {
      this.getDatos();
      this.$emit("rangoFechasCambiado", this.rangoFechas);
    },
    empleado() {
      this.getDatos();
      this.$emit("empleadoCambiado", this.empleado);
    },
    provincias() {
      this.keyLocalidad += 1;
    },
    localidad() {
      this.getDatos();
    },
  },
};
</script>
