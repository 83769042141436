<template>
  <div class="mapControlContainerDatos">
    <div class="titulo">
      <h6>Datos</h6>
      <el-button
        type="text"
        @click="datosControlVisible ? datosControlVisible = false : datosControlVisible = true"
        style="width: 50px"
        icon="el-icon-arrow-down"
      ></el-button>
    </div>
    <div v-if="datosControlVisible">
      <el-button type="text" @click="$refs.modalCobertura.abrir()">Cobertura</el-button>
      <br />
      <el-button type="text" @click="$refs.modalPreciosModa.abrir()">Precios Moda</el-button>
      <br />
      <el-button type="text" @click="$refs.modalPreciosMaximos.abrir()">Precios Máximos</el-button>
      <br />
      <el-button type="text" @click="$refs.modalPreciosMinimos.abrir()">Precios Mínimos</el-button>
    </div>

    <portal to="modales">
      <modal-cobertura ref="modalCobertura"></modal-cobertura>
      <modal-precios-maximos ref="modalPreciosMaximos"></modal-precios-maximos>
      <modal-precios-minimos ref="modalPreciosMinimos"></modal-precios-minimos>
      <modal-precios-moda ref="modalPreciosModa"></modal-precios-moda>
    </portal>
  </div>
</template>

<script>
import ModalCobertura from "./modales/cobertura";
import ModalPreciosMaximos from "./modales/precios_maximos";
import ModalPreciosMinimos from "./modales/precios_minimos";
import ModalPreciosModa from "./modales/precios_moda";

export default {
  name: "datos-mapa",
  components: {
    ModalCobertura,
    ModalPreciosMaximos,
    ModalPreciosMinimos,
    ModalPreciosModa,
  },
  data() {
    return {
      datosControlVisible: true,
      datosEstadistica: null,

      urlTipoCanal: this.$api.URL + "/tipoCanal/obtenerTodosSelect",
      tipoCanal: null,
    };
  },
};
</script>