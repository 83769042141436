var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { style: { height: _vm.mapData.height + "px", width: "100%" } },
        [
          _c(
            "l-map",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "map",
              attrs: { zoom: _vm.mapData.zoom, center: _vm.mapData.center },
              on: {
                click: function(data) {
                  return _vm.$emit("mapClicked", data)
                }
              }
            },
            [
              _c("l-tile-layer", { attrs: { url: _vm.mapData.url } }),
              _c(
                "l-marker-cluster",
                _vm._l(_vm.markers, function(item, index) {
                  return _c("l-marker", {
                    key: index,
                    attrs: {
                      "lat-lng": item.latlng,
                      icon: _vm.getClaseMarcador(item)
                    },
                    on: {
                      click: function($event) {
                        return _vm.$refs.modalVer.abrir(item.id)
                      }
                    }
                  })
                }),
                1
              ),
              _c("l-control", { attrs: { position: "topleft" } }, [
                _c("div", { staticClass: "mapControlContainerFiltros" }, [
                  _c(
                    "div",
                    { staticClass: "titulo" },
                    [
                      _c("h6", [_vm._v("Filtros")]),
                      _c("el-button", {
                        staticStyle: { width: "50px" },
                        attrs: { type: "text", icon: "el-icon-arrow-down" },
                        on: {
                          click: function($event) {
                            _vm.filtrosControlVisible
                              ? (_vm.filtrosControlVisible = false)
                              : (_vm.filtrosControlVisible = true)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.filtrosControlVisible
                    ? _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c("b", [_vm._v("Empleado")]),
                          _c("maca-select-box-empleado", {
                            attrs: { disabled: _vm.$usuarioEs.operador() },
                            model: {
                              value: _vm.empleado,
                              callback: function($$v) {
                                _vm.empleado = $$v
                              },
                              expression: "empleado"
                            }
                          }),
                          _c("br"),
                          _c("b", [_vm._v("Provincia")]),
                          _c("maca-select-box", {
                            attrs: {
                              placeholder: "Provincia",
                              url: _vm.urlProvincia,
                              clearable: "",
                              multiple: "",
                              soloIniciales:
                                _vm.$usuarioEs.operador() ||
                                _vm.$usuarioEs.supervisor()
                            },
                            on: {
                              change: function() {
                                _vm.provinciaCambiada()
                                _vm.getDatos()
                              }
                            },
                            model: {
                              value: _vm.provincias,
                              callback: function($$v) {
                                _vm.provincias = $$v
                              },
                              expression: "provincias"
                            }
                          }),
                          _c("br"),
                          _vm.provincias != null && _vm.provincias.length > 0
                            ? _c(
                                "div",
                                { key: _vm.keyLocalidad },
                                [
                                  _c("b", [_vm._v("Localidad")]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        placeholder: "Localidad",
                                        multiple: ""
                                      },
                                      on: { change: _vm.getDatos },
                                      model: {
                                        value: _vm.localidades,
                                        callback: function($$v) {
                                          _vm.localidades = $$v
                                        },
                                        expression: "localidades"
                                      }
                                    },
                                    _vm._l(_vm.listaLocalidades, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label:
                                            item.nombre +
                                            " (" +
                                            item.provincia +
                                            ")",
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _c("b", [_vm._v("Rango de Fechas")]),
                          _c("maca-input-rango-fecha", {
                            staticStyle: { width: "250px" },
                            model: {
                              value: _vm.rangoFechas,
                              callback: function($$v) {
                                _vm.rangoFechas = $$v
                              },
                              expression: "rangoFechas"
                            }
                          }),
                          _c("br"),
                          _c("b", [_vm._v("Marcas")]),
                          _c("maca-select-box", {
                            attrs: {
                              placeholder: "Marcas",
                              url: _vm.urlMarca,
                              clearable: "",
                              multiple: ""
                            },
                            on: {
                              change: function() {
                                _vm.getDatos()
                              }
                            },
                            model: {
                              value: _vm.marcas,
                              callback: function($$v) {
                                _vm.marcas = $$v
                              },
                              expression: "marcas"
                            }
                          }),
                          _c("br"),
                          _c("b", [_vm._v("Tipo Producto")]),
                          _c("maca-select-box", {
                            attrs: {
                              placeholder: "Tipo Producto",
                              url: _vm.urlTipoProducto,
                              clearable: "",
                              multiple: ""
                            },
                            on: {
                              change: function() {
                                _vm.getDatos()
                              }
                            },
                            model: {
                              value: _vm.tipoProducto,
                              callback: function($$v) {
                                _vm.tipoProducto = $$v
                              },
                              expression: "tipoProducto"
                            }
                          }),
                          _c("br"),
                          _c("b", [_vm._v("Tipo Extra")]),
                          _c("maca-select-box", {
                            attrs: {
                              placeholder: "Tipo Extra",
                              url: _vm.urlTipoExtra,
                              clearable: "",
                              multiple: ""
                            },
                            on: {
                              change: function() {
                                _vm.getDatos()
                              }
                            },
                            model: {
                              value: _vm.tipoExtra,
                              callback: function($$v) {
                                _vm.tipoExtra = $$v
                              },
                              expression: "tipoExtra"
                            }
                          }),
                          _c("br"),
                          _c("b", [_vm._v("Tipo Compra")]),
                          _c("maca-select-box", {
                            attrs: {
                              placeholder: "Tipo Compra",
                              url: _vm.urlTipoCompra,
                              clearable: "",
                              multiple: ""
                            },
                            on: {
                              change: function() {
                                _vm.getDatos()
                              }
                            },
                            model: {
                              value: _vm.tipoCompra,
                              callback: function($$v) {
                                _vm.tipoCompra = $$v
                              },
                              expression: "tipoCompra"
                            }
                          }),
                          _c("br"),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.cargandoExcel,
                                  expression: "cargandoExcel"
                                }
                              ],
                              attrs: {
                                icon: "el-icon-download",
                                type: "text",
                                disabled: _vm.cargandoExcel
                              },
                              on: { click: _vm.descargarExcelIntegrador }
                            },
                            [_vm._v("Excel Integrador")]
                          ),
                          _c("br"),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.cargandoExcel,
                                  expression: "cargandoExcel"
                                }
                              ],
                              attrs: {
                                icon: "el-icon-download",
                                type: "text",
                                disabled: _vm.cargandoExcel
                              },
                              on: { click: _vm.descargarCoberturaPopEx }
                            },
                            [_vm._v("Cobertura Pop/Exhibido")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _c(
                "l-control",
                { attrs: { position: "topright" } },
                [_vm._t("topRight")],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("modal-ver", {
        ref: "modalVer",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }