<template>
  <div>
    <maca-modal titulo="Precios Mínimos" :impedirClose="impedirClose" ref="modal">
      <graficos nombre="Precios Mínimos" nombreUpper="PrecioMinimo" nombreCamelCase="precioMinimo"></graficos>
    </maca-modal>
  </div>
</template>

<style lang="css" scoped>
</style>

<script>
import Graficos from "./graficos";

export default {
  name: "precios-minimos",
  components: {
    Graficos
  },
  data() {
    return {
      impedirClose: false
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    cerrar() {
      this.$refs.modal.cerrar();
    }
  }
};
</script>
