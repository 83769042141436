<template>
  <div style="padding: 0px !important;">
    <mapa filtroMarcasActivo>
      <template slot="topRight">
        <div>
          <datos-mapa></datos-mapa>
        </div>
      </template>
    </mapa>
    <!-- para modales de componentes -->
    <portal-target name="modales"></portal-target>
  </div>
</template>

<style lang="css">
</style>

<script>
import DatosMapa from "./datos_mapa";
import Mapa from "./mapa";

export default {
  name: "home",
  components: { DatosMapa, Mapa },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>
