import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename =
    form.nombreReporte +
    " por " +
    form.nombreEje1 +
    " y " +
    form.nombreEje2 +
    ".xlsx";
  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }

  data.push([
    "Reporte de " +
      form.nombreReporte +
      " por " +
      form.nombreEje1 +
      " y " +
      form.nombreEje2
  ]);
  // rango es requerido
  data.push([
    formatearFecha(form.rango[0]),
    "-",
    formatearFecha(form.rango[1])
  ]);
  if (form.tipoCalibre !== null) {
    data.push([]);
    data.push(["De Calibre:", form.tipoCalibre.nombre]);
  }
  if (form.marca !== null) {
    data.push([]);
    data.push(["De Marca:", form.marca.nombre]);
  }
  if (form.tipoCanal !== null) {
    data.push([]);
    data.push(["De Canal:", form.tipoCanal.nombre]);
  }
  if (form.tipoProducto !== null) {
    data.push([]);
    data.push(["De Tipo Producto:", form.tipoProducto.nombre]);
  }

  data.push([]);

  data.push(["Filtro de Comprador:"]);
  if (form.esCompradorSecco == 0) {
    data.push(["Todos los Compradores"]);
  } else if (form.esCompradorSecco == 1) {
    data.push(["Sólo de Secco"]);
  } else if (form.esCompradorSecco == 2) {
    data.push(["Sólo los que no son de Secco"]);
  }

  data.push([]);

  if (form.esCompradorSecco == 1) {
    data.push(["Tipos de Comprador:"]);

    let fila = [];

    form.tipoComprador.forEach(element => {
      fila.push(element.nombre);
    });

    data.push(fila);
  }

  data.push([]);
  data.push([]);

  let cabezera = [""];

  datos.columnas.forEach(col => {
    cabezera.push(col);
  });

  data.push(cabezera);
  datos.datos.forEach(subArray => {
    // comprobar si todas las columnas de la fina son 0
    // si son 0, no agregar
    let filaZero = false;
    let cantidadZeros = 0;

    datos.columnas.forEach(col => {
      subArray.detalle.forEach(celda => {
        if (celda.nombre == col && celda[form.nombreDatoCelda] == 0) {
          // no agregar si es 0
          cantidadZeros += 1;
        }
      });
    });
    if (cantidadZeros == subArray.detalle.length) {
      filaZero = true;
    }

    if (!filaZero) {
      let fila = [];

      fila.push(subArray.nombre);

      datos.columnas.forEach(col => {
        let celdaValor = "";

        subArray.detalle.forEach(celda => {
          // no agregar si es 0
          if (celda.nombre == col && celda[form.nombreDatoCelda] != 0) {
            celdaValor = celda[form.nombreDatoCelda];
          }
        });

        fila.push(celdaValor);
      });

      data.push(fila);
    }
  });

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFechaMes(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("MMMM YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

export default {
  run
};
