var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.cargando,
              expression: "cargando"
            }
          ]
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "filtrosBox" },
              [
                _c("maca-select-box-empleado", {
                  attrs: { disabled: _vm.$usuarioEs.operador() },
                  on: { change: _vm.getDatos },
                  model: {
                    value: _vm.empleado,
                    callback: function($$v) {
                      _vm.empleado = $$v
                    },
                    expression: "empleado"
                  }
                }),
                _c("maca-select-box", {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    placeholder: "Provincia",
                    url: _vm.urlProvincia,
                    clearable: "",
                    multiple: "",
                    soloIniciales:
                      _vm.$usuarioEs.operador() || _vm.$usuarioEs.supervisor()
                  },
                  on: { change: _vm.provinciaCambiada },
                  model: {
                    value: _vm.provincias,
                    callback: function($$v) {
                      _vm.provincias = $$v
                    },
                    expression: "provincias"
                  }
                }),
                _vm.provincias != null && _vm.provincias.length > 0
                  ? _c(
                      "div",
                      { key: _vm.keyLocalidad },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { placeholder: "Localidad", multiple: "" },
                            on: { change: _vm.getDatos },
                            model: {
                              value: _vm.localidades,
                              callback: function($$v) {
                                _vm.localidades = $$v
                              },
                              expression: "localidades"
                            }
                          },
                          _vm._l(_vm.listaLocalidades, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label:
                                  item.nombre + " (" + item.provincia + ")",
                                value: item.id
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("maca-input-rango-fecha", {
                  staticStyle: { width: "250px", "margin-top": "10px" },
                  on: { change: _vm.getDatos },
                  model: {
                    value: _vm.rangoFechas,
                    callback: function($$v) {
                      _vm.rangoFechas = $$v
                    },
                    expression: "rangoFechas"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filtrosBox" },
              [
                _c(
                  "el-select",
                  {
                    on: { change: _vm.getDatos },
                    model: {
                      value: _vm.esCompradorSecco,
                      callback: function($$v) {
                        _vm.esCompradorSecco = $$v
                      },
                      expression: "esCompradorSecco"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Todos los Compradores", value: 0 }
                    }),
                    _c("el-option", {
                      attrs: { label: "Sólo de Secco", value: 1 }
                    }),
                    _c("el-option", {
                      attrs: { label: "Sólo los que no son de Secco", value: 2 }
                    })
                  ],
                  1
                ),
                _vm.esCompradorSecco == 1
                  ? _c("maca-select-box", {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        placeholder: "Tipo de Comprador",
                        icon: "el-icon-house",
                        url: _vm.urlTipoComprador,
                        seleccionarPrimero: "",
                        multiple: ""
                      },
                      on: { change: _vm.getDatos },
                      model: {
                        value: _vm.tipoComprador,
                        callback: function($$v) {
                          _vm.tipoComprador = $$v
                        },
                        expression: "tipoComprador"
                      }
                    })
                  : _vm._e(),
                _c("maca-select-box", {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    placeholder: "Tipo de Producto",
                    icon: "el-icon-house",
                    url: _vm.urlTipoProducto,
                    clearable: ""
                  },
                  on: { change: _vm.getDatos },
                  model: {
                    value: _vm.tipoProducto,
                    callback: function($$v) {
                      _vm.tipoProducto = $$v
                    },
                    expression: "tipoProducto"
                  }
                }),
                _c("maca-select-box", {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    placeholder: "Tipo de Compra",
                    icon: "el-icon-house",
                    url: _vm.urlTipoCompra,
                    clearable: ""
                  },
                  on: { change: _vm.getDatos },
                  model: {
                    value: _vm.tipoCompra,
                    callback: function($$v) {
                      _vm.tipoCompra = $$v
                    },
                    expression: "tipoCompra"
                  }
                })
              ],
              1
            )
          ]),
          _c("hr"),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "Por Marcas",
                  "active-value": 1,
                  "inactive-value": 0,
                  disabled:
                    _vm.ejeMarca + _vm.ejeTipoCalibre + _vm.ejeTipoCanal == 2 &&
                    !_vm.ejeMarca
                },
                on: {
                  change: function($event) {
                    _vm.limpiarFiltros(), _vm.getDatos()
                  }
                },
                model: {
                  value: _vm.ejeMarca,
                  callback: function($$v) {
                    _vm.ejeMarca = $$v
                  },
                  expression: "ejeMarca"
                }
              }),
              _c("el-switch", {
                staticStyle: { "margin-left": "30px" },
                attrs: {
                  "active-text": "Por Calibres",
                  "active-value": 1,
                  "inactive-value": 0,
                  disabled:
                    _vm.ejeMarca + _vm.ejeTipoCalibre + _vm.ejeTipoCanal == 2 &&
                    !_vm.ejeTipoCalibre
                },
                on: {
                  change: function($event) {
                    _vm.limpiarFiltros(), _vm.getDatos()
                  }
                },
                model: {
                  value: _vm.ejeTipoCalibre,
                  callback: function($$v) {
                    _vm.ejeTipoCalibre = $$v
                  },
                  expression: "ejeTipoCalibre"
                }
              }),
              _c("el-switch", {
                staticStyle: { "margin-left": "30px" },
                attrs: {
                  "active-text": "Por Canales",
                  "active-value": 1,
                  "inactive-value": 0,
                  disabled:
                    _vm.ejeMarca + _vm.ejeTipoCalibre + _vm.ejeTipoCanal == 2 &&
                    !_vm.ejeTipoCanal
                },
                on: {
                  change: function($event) {
                    _vm.limpiarFiltros(), _vm.getDatos()
                  }
                },
                model: {
                  value: _vm.ejeTipoCanal,
                  callback: function($$v) {
                    _vm.ejeTipoCanal = $$v
                  },
                  expression: "ejeTipoCanal"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _vm.ejeTipoCalibre && _vm.ejeTipoCanal
                ? _c("maca-select-box", {
                    attrs: {
                      placeholder: "De todas las marcas",
                      icon: "el-icon-collection-tag",
                      url: _vm.urlMarca,
                      clearable: "",
                      disabled:
                        _vm.datosColumnaSeleccionada != null ||
                        _vm.datosFilaSeleccionada != null
                    },
                    on: { change: _vm.getDatos },
                    model: {
                      value: _vm.marca,
                      callback: function($$v) {
                        _vm.marca = $$v
                      },
                      expression: "marca"
                    }
                  })
                : _vm._e(),
              _vm.ejeMarca && _vm.ejeTipoCanal
                ? _c("maca-select-box", {
                    attrs: {
                      placeholder: "De todos los calibres",
                      icon: "el-icon-s-operation",
                      url: _vm.urlTipoCalibre,
                      clearable: "",
                      disabled:
                        _vm.datosColumnaSeleccionada != null ||
                        _vm.datosFilaSeleccionada != null
                    },
                    on: { change: _vm.getDatos },
                    model: {
                      value: _vm.tipoCalibre,
                      callback: function($$v) {
                        _vm.tipoCalibre = $$v
                      },
                      expression: "tipoCalibre"
                    }
                  })
                : _vm._e(),
              _vm.ejeMarca && _vm.ejeTipoCalibre
                ? _c("maca-select-box", {
                    attrs: {
                      placeholder: "De todos los canales",
                      icon: "el-icon-folder-opened",
                      url: _vm.urlTipoCanal,
                      clearable: "",
                      disabled:
                        _vm.datosColumnaSeleccionada != null ||
                        _vm.datosFilaSeleccionada != null
                    },
                    on: { change: _vm.getDatos },
                    model: {
                      value: _vm.tipoCanal,
                      callback: function($$v) {
                        _vm.tipoCanal = $$v
                      },
                      expression: "tipoCanal"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm.datosBarras != null
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              staticStyle: { "margin-top": "40px" }
            },
            [
              _c("div", [
                _vm.datosColumnaSeleccionada != null
                  ? _c(
                      "div",
                      [
                        _c("b", [
                          _vm._v(
                            'Viendo columna "' +
                              _vm._s(_vm.nombreColumnaSeleccionada) +
                              '"'
                          )
                        ]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                _vm.datosColumnaSeleccionada = null
                              }
                            }
                          },
                          [_vm._v("(Volver)")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.datosFilaSeleccionada != null
                  ? _c(
                      "div",
                      [
                        _c("b", [
                          _vm._v(
                            'Viendo fila "' +
                              _vm._s(_vm.nombreFilaSeleccionada) +
                              '"'
                          )
                        ]),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                _vm.datosFilaSeleccionada = null
                              }
                            }
                          },
                          [_vm._v("(Volver)")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("bar-chart", {
                key: _vm.barChartKey,
                attrs: {
                  height: 225,
                  datasets: _vm.datosBarras.datasets,
                  labels: _vm.datosBarras.labels,
                  options: {
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true
                          }
                        }
                      ]
                    },
                    tooltips: {
                      callbacks: {
                        footer: _vm.obtenerFooterTooltipBarras
                      }
                    },
                    plugins: {
                      datalabels: {
                        color: "#000",
                        anchor: "end",
                        align: "end",
                        offset: 5,
                        formatter: _vm.barFormat
                      }
                    },
                    legend: {
                      labels: {
                        fillStyle: "#555",
                        fontColor: "#00000000"
                      }
                    }
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.datosTabla != null && _vm.datosBarras == null
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              staticStyle: {
                overflow: "auto",
                "margin-top": "20px",
                "max-height": "500px"
              }
            },
            [
              _c(
                "table",
                { key: _vm.tablaKey, staticClass: "graficoTabla" },
                [
                  _c(
                    "tr",
                    [
                      _c("td"),
                      _vm._l(_vm.datosTabla.columnas, function(columna, j) {
                        return _c(
                          "th",
                          { key: j },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.columnaSeleccionada(columna)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(columna) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _vm._l(_vm.datosTabla.datos, function(fila, index) {
                    return _c(
                      "tr",
                      { key: index },
                      [
                        _c(
                          "td",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.filaSeleccionada(fila.nombre)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(fila.nombre) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.datosTabla.columnas, function(columna, j) {
                          return _c(
                            "td",
                            { key: j },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: fila.nombre + " \\\\ " + columna,
                                    placement: "top"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "celda",
                                      attrs: { type: "text" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.obtenerCeldaTabla(columna, fila)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.datosBarras != null || _vm.datosTabla != null
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", disabled: _vm.cargando },
              on: { click: _vm.generarReporte }
            },
            [_vm._v("Exportar a Excel")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }