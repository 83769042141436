<template>
  <div>
    <maca-modal titulo="Datos de Relevamiento" ref="modal">
      <span v-if="cargando">Cargando...</span>
      <table v-if="datos" class="tabla-info">
        <tbody>
        <tr>
          <th>Fecha</th>
          <td v-text="$common.formatearFecha(datos.fecha)"></td>
        </tr>
        <tr>
          <th>Hora</th>
          <td v-text="$common.formatearHora(datos.hora)"></td>
        </tr>
        <tr>
          <th>Empleado</th>
          <td v-text="datos.empleado"></td>
        </tr>
        <tr>
          <th>Tipo Compra</th>
          <td v-text="datos.tipoCompra"></td>
        </tr>
        <tr>
          <th>Punto de Venta</th>
          <td v-text="datos.puntoVenta"></td>
        </tr>
        <tr>
          <th>Canal</th>
          <td v-text="datos.detallePuntoVenta.tipoCanal"></td>
        </tr>
        <tr>
          <th>Provincia</th>
          <td v-text="datos.detallePuntoVenta.provincia"></td>
        </tr>
        <tr>
          <th>Localidad</th>
          <td v-text="datos.detallePuntoVenta.localidad"></td>
        </tr>
        <tr>
          <th>Barrio</th>
          <td v-text="datos.detallePuntoVenta.barrio"></td>
        </tr>
        <tr>
          <th>Dirección</th>
          <td
              v-if="
                  datos.detallePuntoVenta &&
                  datos.detallePuntoVenta.domicilio != ''
                "
              v-text="datos.detallePuntoVenta.domicilio"
          ></td>
          <td v-else>Sin Asignar</td>
        </tr>
        <tr>
          <th>Es Secco</th>
          <td v-text="datos.detallePuntoVenta.compradorSecco ? 'Si' : 'No'"></td>
        </tr>
        <tr v-show="datos.detallePuntoVenta.razonNoCompradorSecco != null">
          <th>Razón No Comprador Secco</th>
          <td v-text="datos.detallePuntoVenta.razonNoCompradorSecco"></td>
        </tr>
        <tr v-show="datos.detallePuntoVenta.tipoCompradorSecco != null">
          <th>Tipo Comprador Secco</th>
          <td>
                <span v-for="(item, index) in datos.detallePuntoVenta.tipoCompradorSecco" :key="index">
                  <span>{{item.nombre}}</span>
                  <span
                      v-if="index != datos.detallePuntoVenta.tipoCompradorSecco.length - 1"
                      style="margin-right: 7px"
                  >,</span>
                </span>
          </td>
        </tr>
        <tr>
          <th>Tipo Distribuidor</th>
          <td v-text="datos.detallePuntoVenta.tipoCompraPuntoVenta"></td>
        </tr>
        <tr>
          <th>Tipo Extra</th>
          <td>
                <span v-for="(item, index) in datos.detallePuntoVenta.tipoExtraPuntoVenta" :key="index">
                  <span>{{item.nombre}}</span>
                  <span
                      v-if="index != datos.detallePuntoVenta.tipoExtraPuntoVenta.length - 1"
                      style="margin-right: 7px"
                  >,</span>
                </span>
          </td>
        </tr>
        <tr>
          <th>Visitado</th>
          <td v-text="datos.detallePuntoVenta.contactarRealizado ? 'Si' : 'No'"></td>
        </tr>
        <tr>
          <th>A Visitar</th>
          <td v-text="datos.detallePuntoVenta.contactar ? 'Si' : 'No'"></td>
        </tr>
        <tr>
          <th>Contacto Nombre</th>
          <td v-text="datos.detallePuntoVenta.duenioNombre"></td>
        </tr>
        <tr>
          <th>Contacto Teléfono</th>
          <td v-text="datos.detallePuntoVenta.duenioTelefono"></td>
        </tr>
        <tr>
          <th>Distribuidor</th>
          <td v-text="datos.detallePuntoVenta.contactarDistribuidorMayorista"></td>
        </tr>
        <tr>
          <th>Observaciones</th>
          <td v-text="datos.observaciones"></td>
        </tr>
        <tr>
          <th>Carga Punto Venta</th>
          <td>
            <span v-if="datos.cargaPuntoVenta">Si</span>
            <span v-else>No</span>
          </td>
        </tr>
        <tr>
          <th>Productos Relevados</th>
          <td>
            <table class="detalleRelevamiento">
              <tr class="header">
                <td style="text-align: center;">Producto</td>
                <td style="text-align: center;">Calibre</td>
                <td style="text-align: center;">Precio</td>
              </tr>
              <tr
                  v-for="(item, index) in datos.detalleRelevamiento"
                  :key="index"
              >
                <td style="text-align: left;" v-text="item.marca"></td>
                <td style="text-align: right;" v-text="item.productoCalibre"></td>
                <td style="text-align: right;" v-text="'$ ' + item.precio"></td>
              </tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </maca-modal>
  </div>
</template>

<style lang="css" scoped>
.detalleRelevamiento td {
  padding: 10px;
  min-width: 80px;
  min-height: 30px;
  border: 1px solid;
}

.detalleRelevamiento .header {
  background: #ddf;
  font-weight: bold;
}
</style>

<script>
export default {
  name: "ver",
  data() {
    return {
      id: null,
      datos: null,

      cargando: false
    };
  },
  methods: {
    abrir(id) {
      this.id = id;
      this.$refs.modal.abrir();

      this.datos = null;
      this.getDatos();
    },
    async getDatos() {
      this.cargando = true;

      let url =
        this.$api.URL + "/relevamiento/obtenerDatos?relevamientoID=" + this.id;

      let respuestaApi = await this.$api.get(url, this.$usuarioToken());

      this.cargando = false;

      if (respuestaApi != null) {
        this.datos = respuestaApi;
      }
    },
    getDireccion() {
      let val = "";

      let calle =
        this.datos.detallePuntoVenta.domicilioCalle == null
          ? ""
          : this.datos.detallePuntoVenta.domicilioCalle;
      let numero =
        this.datos.detallePuntoVenta.domicilioNro == null
          ? ""
          : this.datos.detallePuntoVenta.domicilioNro;
      let localidad =
        this.datos.detallePuntoVenta.domicilioLocalidad == null
          ? ""
          : this.datos.detallePuntoVenta.domicilioLocalidad;
      let zona =
        this.datos.detallePuntoVenta.domicilioZona == null
          ? ""
          : this.datos.detallePuntoVenta.domicilioZona;
      let cp =
        this.datos.detallePuntoVenta.domicilioCodigoPostal == null
          ? ""
          : this.datos.detallePuntoVenta.domicilioCodigoPostal;

      val =
        calle + " " + numero + "," + localidad + " " + zona + " (" + cp + ")";

      if (val.trim() == ",  ()") {
        return "Sin Asignar";
      } else {
        return val;
      }
    }
  }
};
</script>
