var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        { ref: "modal", attrs: { titulo: "Datos de Relevamiento" } },
        [
          _vm.cargando ? _c("span", [_vm._v("Cargando...")]) : _vm._e(),
          _vm.datos
            ? _c("table", { staticClass: "tabla-info" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Fecha")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$common.formatearFecha(_vm.datos.fecha)
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Hora")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$common.formatearHora(_vm.datos.hora)
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Empleado")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.empleado) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Tipo Compra")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.tipoCompra) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Punto de Venta")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.puntoVenta) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Canal")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.tipoCanal
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Provincia")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.provincia
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Localidad")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.localidad
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Barrio")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(_vm.datos.detallePuntoVenta.barrio)
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Dirección")]),
                    _vm.datos.detallePuntoVenta &&
                    _vm.datos.detallePuntoVenta.domicilio != ""
                      ? _c("td", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.datos.detallePuntoVenta.domicilio
                            )
                          }
                        })
                      : _c("td", [_vm._v("Sin Asignar")])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Es Secco")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.compradorSecco
                            ? "Si"
                            : "No"
                        )
                      }
                    })
                  ]),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.datos.detallePuntoVenta.razonNoCompradorSecco !=
                            null,
                          expression:
                            "datos.detallePuntoVenta.razonNoCompradorSecco != null"
                        }
                      ]
                    },
                    [
                      _c("th", [_vm._v("Razón No Comprador Secco")]),
                      _c("td", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.datos.detallePuntoVenta.razonNoCompradorSecco
                          )
                        }
                      })
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.datos.detallePuntoVenta.tipoCompradorSecco !=
                            null,
                          expression:
                            "datos.detallePuntoVenta.tipoCompradorSecco != null"
                        }
                      ]
                    },
                    [
                      _c("th", [_vm._v("Tipo Comprador Secco")]),
                      _c(
                        "td",
                        _vm._l(
                          _vm.datos.detallePuntoVenta.tipoCompradorSecco,
                          function(item, index) {
                            return _c("span", { key: index }, [
                              _c("span", [_vm._v(_vm._s(item.nombre))]),
                              index !=
                              _vm.datos.detallePuntoVenta.tipoCompradorSecco
                                .length -
                                1
                                ? _c(
                                    "span",
                                    { staticStyle: { "margin-right": "7px" } },
                                    [_vm._v(",")]
                                  )
                                : _vm._e()
                            ])
                          }
                        ),
                        0
                      )
                    ]
                  ),
                  _c("tr", [
                    _c("th", [_vm._v("Tipo Distribuidor")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.tipoCompraPuntoVenta
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Tipo Extra")]),
                    _c(
                      "td",
                      _vm._l(
                        _vm.datos.detallePuntoVenta.tipoExtraPuntoVenta,
                        function(item, index) {
                          return _c("span", { key: index }, [
                            _c("span", [_vm._v(_vm._s(item.nombre))]),
                            index !=
                            _vm.datos.detallePuntoVenta.tipoExtraPuntoVenta
                              .length -
                              1
                              ? _c(
                                  "span",
                                  { staticStyle: { "margin-right": "7px" } },
                                  [_vm._v(",")]
                                )
                              : _vm._e()
                          ])
                        }
                      ),
                      0
                    )
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Visitado")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.contactarRealizado
                            ? "Si"
                            : "No"
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("A Visitar")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.contactar ? "Si" : "No"
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Contacto Nombre")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.duenioNombre
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Contacto Teléfono")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta.duenioTelefono
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Distribuidor")]),
                    _c("td", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.datos.detallePuntoVenta
                            .contactarDistribuidorMayorista
                        )
                      }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Observaciones")]),
                    _c("td", {
                      domProps: { textContent: _vm._s(_vm.datos.observaciones) }
                    })
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Carga Punto Venta")]),
                    _c("td", [
                      _vm.datos.cargaPuntoVenta
                        ? _c("span", [_vm._v("Si")])
                        : _c("span", [_vm._v("No")])
                    ])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Productos Relevados")]),
                    _c("td", [
                      _c(
                        "table",
                        { staticClass: "detalleRelevamiento" },
                        [
                          _c("tr", { staticClass: "header" }, [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Producto")]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Calibre")]
                            ),
                            _c(
                              "td",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Precio")]
                            )
                          ]),
                          _vm._l(_vm.datos.detalleRelevamiento, function(
                            item,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c("td", {
                                staticStyle: { "text-align": "left" },
                                domProps: { textContent: _vm._s(item.marca) }
                              }),
                              _c("td", {
                                staticStyle: { "text-align": "right" },
                                domProps: {
                                  textContent: _vm._s(item.productoCalibre)
                                }
                              }),
                              _c("td", {
                                staticStyle: { "text-align": "right" },
                                domProps: {
                                  textContent: _vm._s("$ " + item.precio)
                                }
                              })
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }