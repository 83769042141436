var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0px !important" } },
    [
      _c(
        "mapa",
        { attrs: { filtroMarcasActivo: "" } },
        [
          _c("template", { slot: "topRight" }, [
            _c("div", [_c("datos-mapa")], 1)
          ])
        ],
        2
      ),
      _c("portal-target", { attrs: { name: "modales" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }