<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Bar,
  props: ["labels", "datasets"],
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      datacollection: {
        labels: [
          /*
          arreglo de labels que se aplican a cada data de datasets
          */
        ],
        datasets: [
          /*
          label: "nombre de dataset"
          backgroundColor: "color de las barras"
          data: "array de datos, cantidad igual al arreglo labels"
          */
        ]
      }
    };
  },
  mounted() {
    this.checkearDatos();
  },
  methods: {
    checkearDatos() {
      this.datacollection = {
        labels: [],
        datasets: []
      };
      setTimeout(() => {
        this.datacollection.labels = this.labels;
        this.datasets.forEach(data => {
          this.datacollection.datasets.push(data);
        });

        if (this.datacollection.labels.length == 0) {
          this.checkearDatos();
        } else {
          this.renderChart(this.datacollection, this.options);
        }
      }, 50);
    }
  }
};
</script>
