import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename = "";

  if (form.filtro) {
    filename =
      form.nombreReporte +
      " por " +
      form.nombreEje +
      " de " +
      form.filtro +
      ".xlsx";
  } else {
    filename = form.nombreReporte + " por " + form.nombreEje + ".xlsx";
  }

  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }
  if (form.filtro) {
    data.push([
      "Reporte de " +
        form.nombreReporte +
        " por " +
        form.nombreEje +
        " de " +
        form.filtro
    ]);
  } else {
    data.push(["Reporte de " + form.nombreReporte + " por " + form.nombreEje]);
  }
  // rango es requerido
  data.push([
    formatearFecha(form.rango[0]),
    "-",
    formatearFecha(form.rango[1])
  ]);
  if (form.tipoCalibre !== null) {
    data.push([]);
    data.push(["De Calibre:", form.tipoCalibre.nombre]);
  }
  if (form.marca !== null) {
    data.push([]);
    data.push(["De Marca:", form.marca.nombre]);
  }
  if (form.tipoCanal !== null) {
    data.push([]);
    data.push(["De Canal:", form.tipoCanal.nombre]);
  }
  if (form.tipoProducto !== null) {
    data.push([]);
    data.push(["De Tipo Producto:", form.tipoProducto.nombre]);
  }

  data.push([]);

  data.push(["Filtro de Comprador:"]);
  if (form.esCompradorSecco == 0) {
    data.push(["Todos los Compradores"]);
  } else if (form.esCompradorSecco == 1) {
    data.push(["Sólo de Secco"]);
  } else if (form.esCompradorSecco == 2) {
    data.push(["Sólo los que no son de Secco"]);
  }

  data.push([]);

  if (form.esCompradorSecco == 1) {
    data.push(["Tipos de Comprador:"]);

    let fila = [];

    form.tipoComprador.forEach(element => {
      fila.push(element.nombre);
    });

    data.push(fila);
  }

  data.push([]);
  data.push([]);

  data.push([form.nombreEje, form.nombreDato]);
  for (let index = 0; index < datos.labels.length; index++) {
    const filaNombre = datos.labels[index];
    const filaValor = datos.datasets[0].data[index];

    data.push([filaNombre, filaValor]);
  }

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFechaMes(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("MMMM YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

export default {
  run
};
