import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename = "Reporte Cobertura Pop Exhibido.xlsx";
  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }

  data.push(["Reporte de Cobertura Pop Exhibido"]);
  data.push([]);

  if (form.empleado !== null) {
    data.push([]);
    data.push(["Empleado:", form.empleado.nombre]);
  }
  if (form.provincia !== null) {
    data.push([]);
    let fila = ["Provincias:"];
    form.provincias.forEach(prov => {
      fila.push(prov.nombre);
    });
    data.push(fila);
  }
  if (form.localidad !== null) {
    data.push([]);
    let fila = ["Localidades:"];
    form.localidades.forEach(local => {
      fila.push(local.nombre);
    });
    data.push(fila);
  }
  if (form.rangoFechas !== null) {
    data.push([]);
    data.push([
      "Fecha:",
      formatearFecha(form.rangoFechas[0]),
      " al ",
      formatearFecha(form.rangoFechas[1])
    ]);
  }
  data.push([]);
  data.push(["Cantidad de puntos relevados:", form.cantidadPV]);

  data.push([]);
  data.push([]);

  data.push(["Cobertura", "Pop", "Exhibido"]);
  data.push(["", form.coberturaPop + "%", form.coberturaExhibido + "%"]);

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFechaMes(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("MMMM YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

// Dada una hora de formato hh:mm:ss, devolver hh:mm
function formatearHora(hora) {
  if (hora == null) {
    return;
  }
  return hora.substr(0, 5);
}

function getDireccion(detallePuntoVenta) {
  let val = "";

  let calle =
    detallePuntoVenta.domicilioCalle == null
      ? ""
      : detallePuntoVenta.domicilioCalle;
  let numero =
    detallePuntoVenta.domicilioNro == null
      ? ""
      : detallePuntoVenta.domicilioNro;
  let localidad =
    detallePuntoVenta.domicilioLocalidad == null
      ? ""
      : detallePuntoVenta.domicilioLocalidad;
  let zona =
    detallePuntoVenta.domicilioZona == null
      ? ""
      : detallePuntoVenta.domicilioZona;
  let cp =
    detallePuntoVenta.domicilioCodigoPostal == null
      ? ""
      : detallePuntoVenta.domicilioCodigoPostal;

  val = calle + " " + numero + "," + localidad + " " + zona + " (" + cp + ")";

  if (val.trim() == ",  ()") {
    return "Sin Asignar";
  } else {
    return val;
  }
}

export default {
  run
};
