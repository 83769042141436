import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename = "Reporte Excel Integrador.xlsx";
  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }

  data.push(["Reporte de Excel Integrador"]);
  data.push([]);

  if (form.empleado !== null) {
    data.push([]);
    data.push(["Empleado:", form.empleado.nombre]);
  }
  if (form.provincia !== null) {
    data.push([]);
    let fila = ["Provincias:"];
    form.provincias.forEach(prov => {
      fila.push(prov.nombre);
    });
    data.push(fila);
  }
  if (form.localidad !== null) {
    data.push([]);
    let fila = ["Localidades:"];
    form.localidades.forEach(local => {
      fila.push(local.nombre);
    });
    data.push(fila);
  }
  if (form.rangoFechas !== null) {
    data.push([]);
    data.push([
      "Fecha:",
      formatearFecha(form.rangoFechas[0]),
      " al ",
      formatearFecha(form.rangoFechas[1])
    ]);
  }

  data.push([]);
  data.push([]);

  let titulosPrecios = [];
  let keysPrecios = [];
  let titulosCobertura = [];
  let keysCobertura = [];

  if (datos.detalle.length > 0) {
    Object.keys(datos.detalle[0]).forEach(keyDetalle => {
      if (keyDetalle.includes("precioModa_")) {
        titulosPrecios.push(
          "Precio Moda " + keyDetalle.substr(11, keyDetalle.length)
        );
        keysPrecios.push(keyDetalle);
      }
    });
    Object.keys(datos.detalle[0]).forEach(keyDetalle => {
      if (keyDetalle.includes("cobertura_")) {
        titulosCobertura.push(
          "Cobertura " + keyDetalle.substr(10, keyDetalle.length)
        );
        keysCobertura.push(keyDetalle);
      }
    });
  }

  let titulos = [
    "Relevador",
    "Provincia",
    "Localidad",
    "Mes",
    "Año",
    "Tipo de Producto",
    "Marca",
    "Calibre"
  ];

  titulosPrecios.forEach(fila => {
    titulos.push(fila);
  });
  titulosCobertura.forEach(fila => {
    titulos.push(fila);
  });

  titulos.push("Cobertura Marca Calibre");
  titulos.push("Precio Moda Marca Calibre");

  data.push(titulos);
  datos.detalle.forEach(datosFila => {
    let fila = [
      datosFila.relevador,
      datosFila.provincia,
      datosFila.localidad,
      datosFila.mes,
      datosFila.anio,
      datosFila.tipoProducto,
      datosFila.marca,
      datosFila.calibre
    ];

    keysPrecios.forEach(key => {
      fila.push(datosFila[key]);
    });
    keysCobertura.forEach(key => {
      fila.push(datosFila[key]);
    });

    fila.push(datosFila.coberturaMarcaCalibre);
    fila.push(datosFila.precioModaMarcaCalibre);

    data.push(fila);
  });

  data.push([]);
  data.push([]);

  data.push([
    "Provincia",
    "Localidad",
    "Marca",
    "Total Cobertura Marca",
    "Total Precio Moda Marca"
  ]);
  datos.resumen.forEach(fila => {
    if (fila.coberturaMarca > 0 && fila.precioModaMarca > 0) {
      data.push([
        fila.provincia,
        fila.localidad,
        fila.marca,
        fila.coberturaMarca,
        fila.precioModaMarca
      ]);
    }
  });

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

export default {
  run
};
